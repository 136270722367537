import * as React from "react"

import Header from "./header/header"

import "./layout.css"
import { useLocation } from "@reach/router"
import { createTheme, MuiThemeProvider} from "@material-ui/core";
import Footer from "./footer/footer";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#4E3EFD"
    },
    text: {
      primary: "rgb(40,40,40)"
    },
    grey: {
      "50": ""
    },
    background: {
      default: "rgb(247, 248, 251)",
      paper: "rgb(255,255,255)"
    }
  }
});

const Layout = ({ children }) => {
  const location = useLocation()

  return (
    <MuiThemeProvider theme={MuiTheme}>
      <Header from={location.pathname}/>
      <div  >
        <main>{children}</main>

      </div>
      <Footer/>
    </MuiThemeProvider>
  )
}

export default Layout
