import React from "react"
import Grid from "@material-ui/core/Grid"
import Text from "@material-ui/core/typography"
import { StaticImage } from "gatsby-plugin-image"
import CopyrightIcon from "@material-ui/icons/Copyright"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import image from "../../images/store.png"

const Footer = () => {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <footer>
      <Grid
        container
        justifyContent="center"
        style={{
          height: smDown ? "360px" : "473px",
          backgroundColor: "#FAFAFA",
        }}
      >
        <Grid item lg={5}>
          {smDown ? null : (
            <>
              {" "}
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: smDown ? "30px" : "50px",
                  paddingTop: "65px",
                  fontWeight: "bold",
                  paddingLeft: smDown ? "0px" : "unset",
                }}
              >
                On the move?
              </Text>
              <Text
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "15px",
                  paddingLeft: smDown ? "6px" : "unset",
                }}
              >
                Download the Istanbul doner App and <br></br> order your food in
                a second
              </Text>{" "}
            </>
          )}

          {smDown ? null : (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <StaticImage
                src="../../images/icons8-facebook-f-30.png"
                quality={95}
                width={25}
                formats={["auto", "webp", "avif"]}
                alt="mission"
                style={{
                  top: "16ch",
                  //left: "1ch",
                }}
              />
              <StaticImage
                src="../../images/icons8-instagram-48.png"
                quality={95}
                width={25}
                formats={["auto", "webp", "avif"]}
                alt="mission"
                style={{
                  top: "16ch",
                }}
              />
            </div>
          )}

          {smDown ? null : (
            <div>
              <img
                src={image}
                alt="none "
                style={{ width: "170px", marginTop: "17ch" }}
              ></img>
              <StaticImage
                src="../../images/PLA.png"
                quality={95}
                width={170}
                formats={["auto", "webp", "avif"]}
                alt="mission"
                style={{
                  top: "17ch",
                }}
              />
            </div>
          )}
        </Grid>
        {smDown ? null : (
          <Grid item lg={3}>
            <StaticImage
              src="../../images/footer.png"
              quality={95}
              width={319}
              formats={["auto", "webp", "avif"]}
              alt="mission"
              style={{
                left: "10ch",
                top: "4.0ch",
              }}
            />
          </Grid>
        )}

        {smDown && (
          <Container
            maxWidth="lg"
            style={{
              display: "flex",
            }}
          >
            <Grid container>
              <Grid item lg={12}>
                <Text
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: smDown ? "30px" : "50px",
                    paddingTop: "65px",
                    fontWeight: "bold",
                  }}
                >
                  On the move?
                </Text>
                <Text
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "15px",
                  }}
                >
                  Download the Istanbul doner App and <br></br> order your food
                  in a second
                </Text>
              </Grid>
              <Grid item xs={6} sm={6} style={{}}>
                <img
                  src={image}
                  alt="none "
                  style={{ width: "170px", margingTop: "-12px" }}
                ></img>
              </Grid>
              <Grid item xs={6} sm={6}>
                <StaticImage
                  src="../../images/PLA.png"
                  quality={95}
                  height={70}
                  formats={["auto", "webp", "avif"]}
                  alt="mission"
                  style={{}}
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        style={{
          alignItems: "center",
          backgroundColor: "#F4CC3B",
          height: "60px",
        }}
      >
        <Grid item lg={10}>
          <Text
            style={{
              fontFamily: "Poppins",
              fontSize: "11px",
              fontWeight: "bold",
              paddingLeft: "12px",
              color: "white",
              display: "flex",
              alignItems: "center",
              top: "15px",
            }}
          >
            {" "}
            <CopyrightIcon fontSize="small" style={{ marginRight: "10px" }} />
            2021 Doctors for Syria | All Rights Reserved | Cookies and Tracking
            Accessibility | Privacy Policy Terms Of Use{" "}
          </Text>{" "}
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
