import * as React from 'react'
import {ReactNode} from 'react';
import classNames from 'classnames';
import { Button, ButtonProps, CircularProgress, makeStyles } from '@material-ui/core';

const style = makeStyles((theme) => ({
     root: {
          textTransform: 'unset',
          color: "#000000",
         fontWeight:"bold",
          fontSize:"20px",
          textDecoration:"none",

          
          transition:'0.3s',
          '&:hover': {
               background: 'unset',
               boxShadow: 'none',
               textDecoration:"underline"
          },
     },
     textSizeSmall: {
         
          fontSize:'15px'
     },
     textSizeLarge: {
         
          fontSize:'16px'
     },
     loading: {
          color: "black",
     },
}));

interface Props extends ButtonProps {
     children: ReactNode,
     isLoading?: boolean
}

const TextButton = ({children, size, isLoading = false, ...props}: Props) => {
        // @ts-ignore
     const classes = style();

     return (
        <Button
       
           className={classNames({
                [classes.root]: true,
                [classes.textSizeSmall]: size && size === 'small',
                [classes.textSizeLarge]: size && size === 'large',
           })}
           disableRipple={true}
           disabled={props.disabled || isLoading}
           onClick={props.onClick ?? null}
           {...props}
           
        >
            
             {!isLoading ? children  : null}

             {isLoading &&
             <CircularProgress classes={{colorPrimary: classes.loading}} size={18} thickness={8}/>
             }
        </Button>
     );
};

export default TextButton;