import * as React from "react"
import PropTypes, { string } from "prop-types"
import { Link } from "gatsby"
import TextButton from "../buttons/text_button"
import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Container from "@material-ui/core/Container"
import { StaticImage } from "gatsby-plugin-image"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme, makeStyles } from "@material-ui/core"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import image from "../../images/Logo.png"
import orderNow from "../../images/orderNow.png"

const useStyles = makeStyles({
  drawerPaper: {
    backgroundColor: "black",
  },
})

const Header = props => {
  const from = props.from
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [expanded, setExpanded] = React.useState(false)

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  return (
    <header
      style={{
        position: "absolute",
        top: "0px",
        left: "0px",
        zIndex: 1300,
        width: "100%",
      }}
    >
      <AppBar
        position="static"
        elevation={0}
        style={
          from === "/"
            ? { backgroundColor: "transparent" }
            : {
                boxShadow: "0px 4px 12px -8.5px rgba(0,0,0,0.7)",
                backgroundColor: "black",
              }
        }
      >
        <Toolbar
          style={
            from === "/"
              ? {
                  display: "flex",
                }
              : {
                  backgroundColor: "black",
                  // height: "70px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }
          }
        >
          {smDown ? (
            ""
          ) : (
            <Container
              maxWidth="lg"
              style={{
                display: "flex",
              }}
            >
              <Link to="/">
                <StaticImage
                  src="../../images/Logo.png"
                  width={200}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="zamzam"
                />
              </Link>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <TextButton
                    style={
                      from === "/"
                        ? {
                            textTransform: "unset",
                            color: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    Menu
                  </TextButton>
                </Link>
                <Link
                  to="/about"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <TextButton
                    style={
                      from === "/"
                        ? {
                            textTransform: "unset",
                            color: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    About us
                  </TextButton>
                </Link>
                <Link
                  to="/locations"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <TextButton
                    style={
                      from === "/"
                        ? {
                            textTransform: "unset",
                            color: "white",
                          }
                        : { color: "white" }
                    }
                  >
                    Location
                  </TextButton>
                </Link>
                <a
                  href="https://www.order.store/store/istanbul-doner-%26-kebap/vb3iJKxqRGqYyPdAnmvsgw?utm_source=web-restaurant-manager/"
                  target="_blank"
                >
                  {" "}
                  <img src={orderNow} style={{ marginTop: "28px" }}></img>
                </a>
              </Grid>
            </Container>
          )}
        </Toolbar>
      </AppBar>
      {smDown ? (
        <div
          style={{
            width: "100%",
            backgroundColor: "black",
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <AppBar position="fixed" style={{ backgroundColor: "black" }}>
            <Toolbar
              style={{
                backgroundColor: "black",
                height: "20px",
              }}
            >
              <Link to="/">
                <img
                  src={image}
                  style={{
                    height: "50px",
                    width: "auto",
                    marginTop: "2.5ch",
                  }}
                  alt="zamzam"
                />
              </Link>
              <IconButton
                styly={{
                  backgroundColor: "white",
                  marginLeft: "170px",
                }}
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
              >
                <MenuIcon style={{ color: "white", marginLeft: "170px" }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div style={{ backgroundColor: "black" }}>
              <IconButton
                onClick={handleDrawerClose}
                style={{ color: "white" }}
              >
                <Link to="/">
                  <img
                    src={image}
                    style={{
                      height: "50px",
                      width: "auto",
                      paddingRight: "200px",
                      marginTop: "10px",
                    }}
                    alt="istanbul"
                  />
                </Link>
                {theme.direction === "ltr" ? <CloseIcon /> : <CloseIcon />}
              </IconButton>
            </div>
            <div style={{ backgroundColor: "black" }}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                }}
              >
                <TextButton
                  style={{
                    paddingTop: "50px",
                    color: "white",
                  }}
                  onClick={handleDrawerClose}
                >
                  Menu
                </TextButton>
              </Link>
              <br />
              <Link
                to="/about"
                style={{
                  textDecoration: "none",
                }}
              >
                <TextButton
                  style={{
                    color: "white",
                  }}
                  onClick={handleDrawerClose}
                >
                  About Us
                </TextButton>
              </Link>
              <br />
              <Link
                to="/locations"
                style={{
                  textDecoration: "none",
                }}
              >
                <TextButton
                  style={{
                    color: "white",
                  }}
                  onClick={handleDrawerClose}
                >

                  Locations
                </TextButton>
              </Link>
              <br />
              <a
                href="https://www.order.store/store/istanbul-doner-%26-kebap/vb3iJKxqRGqYyPdAnmvsgw?utm_source=web-restaurant-manager/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <TextButton
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  Order Now
                </TextButton>
              </a>
            </div>
          </Drawer>
        </div>
      ) : (
        ""
      )}
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
